import {
  Box,
  Button,
  ButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const CategorySelector = ({
  categories,
  filterItem,
  setFilterItem,
  setFilterItemId,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box display='flex' justifyContent='center' mt={2}>
      <ButtonGroup
        orientation={isMobile ? "vertical" : "horizontal"}
        sx={{
          display: "flex",
          flexWrap: isMobile ? "nowrap" : "wrap",
          gap: theme.spacing(1.5),
        }}
      >
        {categories.map((item) => (
          <Button
            key={item.id}
            size='large'
            sx={{
              color: "#801F56",
              border: "2px solid #801F56",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "bold",
              padding: "8px 16px",
              backgroundColor:
                filterItem === item.name ? "#F0C9D5" : "transparent",
              "&:hover": {
                backgroundColor: "#F0C9D5",
                border: "2px solid #801F56",
              },
            }}
            onClick={() => {
              setFilterItem(item.name);
              setFilterItemId(item.id);
            }}
          >
            {item.name}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default CategorySelector;
