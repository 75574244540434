import React from "react";
import CategoriesIndex from "../Catalogo/CategoriesIndex";
import Layout from "../../components/Layout/Layout";
import { Banner } from "../../components/Layout/Banner";
import { Info } from "../Catalogo/Info";

const Index = () => {
  //Esta es la pagina principal de la tienda autenticado
  return (
    <>
      <Layout>
        <Banner />
        <CategoriesIndex />
        <Info />
      </Layout>
    </>
  );
};

export default Index;
