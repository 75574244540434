import React from "react";
import { Footer } from "../../components/Layout/Footer";
import { Header1 } from "../../components/Layout/Header1.js";
import { Banner } from "../../components/Layout/Banner.js";
import CategoriesIndex from "./CategoriesIndex.js";
import { Discount } from "./Discount.js";
import { Info } from "./Info.js";
const Catalogo = () => {
  return (
    <>
      <Header1 />
      <Banner />
      <CategoriesIndex />
      <Discount />
      <Info />
      <Footer />
    </>
  );
};

export default Catalogo;
