import { Card } from "./Card/Card";
import { useContext, useEffect, useState } from "react";
import ShowToast from "../../utils/ShowToast";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../Context/auth/AuthContext";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Divider,
  Card as MUICard,
  Paper,
} from "@mui/material";
import MethodGet from "../../config/Service";
import CartContext from "../../Context/Cart/CartContext";
import FormatCurrency from "../../utils/FormatCurrency";
export const Cart = () => {
  const { cart, setCart } = useContext(CartContext);
  const cartlocal = localStorage.getItem("cart");
  const storedCoupon =
    JSON.parse(localStorage.getItem("appliedCoupon")) || null;
  const { autenticado } = useContext(AuthContext);
  const [coupon, setCoupon] = useState(storedCoupon ? storedCoupon.code : "");
  const [couponData, setCouponData] = useState(storedCoupon);
  const [discount, setDiscount] = useState(
    storedCoupon ? storedCoupon.discount : 0
  );

  const total = cart.reduce(
    (total, item) => total + Number(item.final_price),
    0
  );
  const discountedTotal = total - (total * discount) / 100;
  const eliminarProducto = (productId) => {
    const todosProducto = cart.filter((c) => c.product_id !== productId);
    setCart(todosProducto);
  };

  const handleCouponApply = async (e) => {
    e.preventDefault();

    try {
      const res = await MethodGet(
        `/distribucion/couponValidity?code=${coupon}`
      );

      if (res.data.coupon) {
        setCouponData(res.data.coupon);
        setDiscount(res.data.coupon.discount);
        localStorage.setItem("appliedCoupon", JSON.stringify(res.data.coupon));
        ShowToast(
          "success",
          "Correcto",
          `Cupón aplicado! Descuento del ${res.data.coupon.discount}%`
        );
      } else {
        setDiscount(0);
        setCouponData(null);
        localStorage.removeItem("appliedCoupon");
        ShowToast(
          "error",
          "Atención",
          "El código de cupón no es válido o no existe"
        );
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
      setDiscount(0);
      setCouponData(null);
      localStorage.removeItem("appliedCoupon");
      ShowToast("error", "Atención", "Error al validar el cupón");
    }
  };
  const handleProductQuantity = (change, cantidad, product_id, stock) => {
    const updatedCart = cart.map((item) => {
      if (item.product_id === product_id) {
        const newCantidad =
          change === "increment"
            ? Math.min(cantidad + 1, stock) // No exceder el stock disponible
            : Math.max(cantidad - 1, 1); // No permitir cantidades menores que 1

        return {
          ...item,
          cantidad: newCantidad,
          final_price: item.price * newCantidad,
        };
      }
      return item;
    });
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleRemoveCoupon = () => {
    setCoupon("");
    setDiscount(0);
    setCouponData(null);
    localStorage.removeItem("appliedCoupon");
    ShowToast(
      "success",
      "Cupón eliminado",
      "El cupón ha sido eliminado correctamente"
    );
  };

  return (
    <Box sx={{ p: 1 }}>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            {cart.length > 0 ? (
              <div style={{ maxHeight: "650px", overflowY: "auto" }}>
                {cart.map((cart) => (
                  <MUICard key={cart.product_id} sx={{ mb: 2, p: 1 }}>
                    <Card
                      cart={cart}
                      onChangeQuantity={(change, cantidad) =>
                        handleProductQuantity(
                          change,
                          cantidad,
                          cart.product_id,
                          cart.stock
                        )
                      }
                      eliminarProducto={() => eliminarProducto(cart.product_id)}
                    />
                  </MUICard>
                ))}
              </div>
            ) : (
              cart.length === 0 && (
                <Typography variant='h6' align='center'>
                  No hay productos en el carrito
                </Typography>
              )
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            {autenticado && (
              <Box sx={{ mb: 3 }}>
                {discount > 0 ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant='body1'>
                      Cupón aplicado: <strong>{couponData?.code}</strong>
                    </Typography>
                    <Button
                      variant='contained'
                      color='error'
                      onClick={handleRemoveCoupon}
                    >
                      Eliminar cupón
                    </Button>
                  </Box>
                ) : (
                  cart.length > 0 && (
                    <form onSubmit={handleCouponApply}>
                      <TextField
                        fullWidth
                        placeholder='Ingresa el código de promoción'
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <Button
                        variant='contained'
                        type='submit'
                        fullWidth
                        sx={{
                          backgroundColor: "#801f56",
                          "&:hover": { backgroundColor: "#801f56" },
                        }}
                      >
                        Aplicar
                      </Button>
                    </form>
                  )
                )}
              </Box>
            )}
            <Divider />
            <Box sx={{ mt: 3 }}>
              <Typography variant='body1'>
                Subtotal: <strong>${total.toFixed(2)}</strong>
              </Typography>
              <Typography variant='body1'>
                Cupón de descuento:{" "}
                <strong>{discount > 0 ? `${discount}%` : "N/A"}</strong>
              </Typography>
              <Typography variant='h6' sx={{ mt: 2 }}>
                Total: <strong>{FormatCurrency(discountedTotal)}</strong>
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              {autenticado ? (
                <Link to='/pagar-carrito'>
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{
                      backgroundColor: "#801f56",
                      "&:hover": { backgroundColor: "#801f56" },
                    }}
                  >
                    Continuar
                  </Button>
                </Link>
              ) : (
                <Link to='/iniciar-sesion'>
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{
                      backgroundColor: "#801f56",
                      "&:hover": { backgroundColor: "#801f56" },
                    }}
                  >
                    Iniciar sesión para pagar
                  </Button>
                </Link>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
