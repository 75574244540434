import React, { useEffect, useState } from "react";
import Select from "react-select";

const CfdiSelect = (props) => {
  const cfdi = [
    { name: "Adquisición de mercancías.", value: "G01" },
    { name: "Gastos en general.", value: "G03" },
    { name: "Sin efectos fiscales.", value: "S01" },
  ];

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  // Estado para manejar el valor seleccionado
  const [selectedCfdi, setSelectedCfdi] = useState(null);

  useEffect(() => {
    if (props.defaultCfdi) {
      const defaultOption = cfdi.find(
        (option) => option.value === props.defaultCfdi
      );
      setSelectedCfdi(
        defaultOption
          ? {
              label: `${defaultOption.value}-${defaultOption.name}`,
              value: defaultOption.value,
            }
          : null
      );
    }
  }, [props.defaultCfdi]); // Se ejecuta cuando `defaultCfdi` cambia

  const detectarCambiosCfdi = (value) => {
    setSelectedCfdi(value); // Actualiza el estado interno
    props.detectarCambiosCfdi(value); // Llama a la función del padre
  };

  return (
    <Select
      onChange={detectarCambiosCfdi}
      className='basic-single'
      classNamePrefix='select'
      styles={selectStyles}
      name='cfdi_select'
      placeholder='Uso de CFDI'
      options={cfdi.map((option) => ({
        label: `${option.value}-${option.name}`,
        value: option.value,
      }))}
      value={selectedCfdi} // Usamos el estado para reflejar el valor actual
    />
  );
};

export default CfdiSelect;
