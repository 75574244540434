import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Collapse,
  Snackbar,
} from "@mui/material";
import { DeleteAccountIcon } from "../../icons/DeleteAccountIcon";
import WhatsAppIcon from "../../icons/WhatsAppIcon";
import FiscalIcon from "../../icons/FiscalIcon";
import AuthContext from "../../../Context/auth/AuthContext";
import TaxFiscalSelect from "../../../containers/SelectOptions/TaxFiscalSelect";
import CfdiSelect from "../../../containers/SelectOptions/CFDISelect";
import GiftIcon from "../../icons/GiftIcon";

const DeleteAccount = () => {
  const { eliminarCuenta, saveFiscalData, usuario, getFiscalData } =
    useContext(AuthContext);
  const [showFiscalForm, setShowFiscalForm] = useState(false);
  const [initialFiscalData, setInitialFiscalData] = useState(null); // Datos originales
  const [formData, setFormData] = useState({}); // Datos actuales del formulario
  const [isModified, setIsModified] = useState(false); // Habilitar botón si se modifican datos
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const detectarCambios = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };
  const [taxFiscal, setTaxFiscal] = useState(
    initialFiscalData ? initialFiscalData.taxt_system : null
  ); // Estado para almacenar el valor del select de régimen fiscal
  const detectarCambiosTaxFiscal = (value) => {
    setTaxFiscal(value.value);
  };

  const [cfdi, setCfdi] = useState(
    initialFiscalData ? initialFiscalData.cfdi : null
  ); // Estado para almacenar el valor del select de CFDI
  const detectarCambiosCfdi = (value) => {
    setCfdi(value.value);
  };
  // Función para cargar datos fiscales al abrir el formulario
  useEffect(() => {
    const fetchFiscalData = async () => {
      try {
        if (showFiscalForm && !initialFiscalData) {
          const data = await getFiscalData(usuario.id);
          setInitialFiscalData(data);
          setCfdi(data.cfdi);
          setTaxFiscal(data.taxt_system);
          setFormData(data); // Inicializa el formulario con los datos existentes
        }
      } catch (error) {
        console.error("Error al cargar los datos fiscales:", error);
      }
    };
    fetchFiscalData();
  }, [showFiscalForm, usuario.id, initialFiscalData]);

  // Verifica si los datos han cambiado
  useEffect(() => {
    if (!initialFiscalData) return;

    // Verifica si alguno de los datos de formulario o seleccionados ha cambiado
    const hasChanged =
      Object.keys(initialFiscalData).some(
        (key) => initialFiscalData[key] !== formData[key]
      ) ||
      initialFiscalData.tax_system !== taxFiscal ||
      initialFiscalData.cfdi !== cfdi;

    setIsModified(hasChanged);
  }, [formData, initialFiscalData, taxFiscal, cfdi]);

  const handleSubmitFiscal = async (event) => {
    event.preventDefault();

    saveFiscalData({
      ...formData,
      id: usuario.id,
      cfdi: cfdi,
      taxt_system: taxFiscal,
      taxt_city: formData.taxt_city,
      taxt_country: "MEX",
      email: formData.email,
    });
  };

  const detailBlock = [
    {
      icon: <WhatsAppIcon />,
      step: "Solicitar ayuda via whatsapp",
      title: "Si tienes alguna duda, Solicitar Ayuda ahora",
      action: () => {
        window.open(
          "https://wa.me/5618391680?text=Hola necesito de su ayuda para consultar el status de mi pedido",
          "_blank"
        );
      },
    },
    // {
    //   icon: <GiftIcon />,
    //   step: "Mis puntos de regalo",
    //   title: "Actualmente tienes 1302 puntos disponibles",
    // },
    {
      icon: <FiscalIcon />,
      step: "Registrar datos fiscales",
      title: "Registra tu información fiscal para facturación",
      action: () => {
        setShowFiscalForm(!showFiscalForm);
      },
    },
    {
      icon: <DeleteAccountIcon />,
      step: "Eliminar cuenta",
      title: "Eliminar cuenta de forma definitiva, se perderá todo acceso",
      action: () => {
        eliminarCuenta();
      },
    },
  ];

  return (
    <Box sx={{ p: 3, backgroundColor: "#f9f9f9", borderRadius: 2 }}>
      <Grid container spacing={2}>
        {detailBlock.map((block, index) => (
          <Grid item xs={12} key={index}>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
                borderRadius: 2,
                backgroundColor: "white",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
              onClick={block.action}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: 2,
                  width: 50,
                  height: 50,
                  backgroundColor: "#e0e0e0",
                  borderRadius: "50%",
                }}
              >
                {block.icon}
              </Box>
              <Box>
                {block.step && (
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    fontWeight='bold'
                  >
                    {block.step}
                  </Typography>
                )}
                <Typography variant='body1'>{block.title}</Typography>
              </Box>
            </Paper>

            {block.step === "Registrar datos fiscales" && (
              <Collapse in={showFiscalForm} timeout='auto' unmountOnExit>
                <Paper
                  elevation={3}
                  sx={{
                    mt: 2,
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Typography variant='h6' fontWeight='bold' gutterBottom>
                    Datos Fiscales
                  </Typography>
                  <form onSubmit={handleSubmitFiscal}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='RFC'
                          name='RFC'
                          required
                          value={formData.RFC || ""}
                          autoComplete='off'
                          onChange={(e) =>
                            detectarCambios("RFC", e.target.value)
                          }
                          placeholder='Ejemplo: ABCD123456XYZ'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Razón Social'
                          name='business_name'
                          autoComplete='off'
                          required
                          value={formData.business_name || ""}
                          placeholder='Nombre de la empresa o persona física'
                          onChange={(e) =>
                            detectarCambios("business_name", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Typography variant='caption'>
                          regimen fiscal:
                        </Typography>
                        <TaxFiscalSelect
                          detectarCambiosTaxFiscal={detectarCambiosTaxFiscal}
                          defaultTax={
                            initialFiscalData
                              ? initialFiscalData?.tax_system
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Typography variant='caption'>Uso CFDI:</Typography>
                        <CfdiSelect
                          detectarCambiosCfdi={detectarCambiosCfdi}
                          defaultCfdi={
                            initialFiscalData ? initialFiscalData?.cfdi : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Calle'
                          name='taxt_street'
                          autoComplete='off'
                          required
                          value={formData.taxt_street || ""}
                          placeholder='calle'
                          onChange={(e) =>
                            detectarCambios("taxt_street", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Codigo Postal'
                          name='taxt_zip'
                          autoComplete='off'
                          required
                          value={formData.taxt_zip || ""}
                          placeholder='50000'
                          onChange={(e) =>
                            detectarCambios("taxt_zip", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Numero Exterior'
                          name='taxt_exterior'
                          autoComplete='off'
                          required
                          value={formData.taxt_exterior || ""}
                          placeholder='8'
                          onChange={(e) =>
                            detectarCambios("taxt_exterior", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Numero Interior'
                          name='taxt_interior'
                          autoComplete='off'
                          value={formData.taxt_interior || ""}
                          placeholder='Ejemplo: 8A'
                          onChange={(e) =>
                            detectarCambios("taxt_interior", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Estado'
                          name='taxt_state'
                          autoComplete='off'
                          required
                          value={formData.taxt_state || ""}
                          placeholder='Ejemplo: Estado de mexico'
                          onChange={(e) =>
                            detectarCambios("taxt_state", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Ciudad'
                          name='taxt_city'
                          autoComplete='off'
                          required
                          value={formData.taxt_city || ""}
                          placeholder='Ejemplo: Toluca de Lerdo'
                          onChange={(e) =>
                            detectarCambios("taxt_city", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Municipio'
                          name='taxt_municipality'
                          autoComplete='off'
                          required
                          value={formData.taxt_municipality || ""}
                          placeholder='Ejemplo: toluca'
                          onChange={(e) =>
                            detectarCambios("taxt_municipality", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TextField
                          fullWidth
                          label='Correo'
                          name='email'
                          autoComplete='off'
                          required
                          value={formData.email || ""}
                          placeholder='Ejemplo: alguien@algo.com'
                          onChange={(e) =>
                            detectarCambios("email", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                          type='submit'
                          variant='contained'
                          sx={{
                            backgroundColor: "#801F56",
                            "&:hover": { backgroundColor: "#801F56" },
                          }}
                          disabled={!isModified}
                        >
                          Guardar Datos Fiscales
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Collapse>
            )}
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
        message={alert.message}
        severity={alert.severity}
      />
    </Box>
  );
};

export default DeleteAccount;
