import React, { useState, useEffect, useContext } from "react";
import MethodGet from "../../config/Service";
import { SectionTitle } from "../../components/SectionTitle";
import CardProduct from "../../components/Products/CardProduct";
import ProductsContext from "../../Context/Products/ProductsContext";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Pagination,
  useTheme,
  ButtonGroup,
  Button,
  useMediaQuery,
  Chip,
  Typography,
} from "@mui/material";
import SearchProducts from "./SearchProducts";
import ScrollableSubcategories from "./ScrollableSubcategories";
import CategorySelector from "./CategorySelector";

const useStyles = makeStyles((theme) => ({
  containerPagination: {
    padding: "0px 10px",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}));

const CategoriesIndex = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { products, ProductsByCategory, current_page, total, per_page } =
    useContext(ProductsContext);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [filterItem, setFilterItem] = useState("");
  const [filterItemId, setFilterItemId] = useState(null);
  const [filterItemSubcategory, setFilterItemSubcategory] = useState(null);
  const [results, setResults] = useState([]);
  const [subcategories, setSubcategories] = useState(null);

  useEffect(() => {
    MethodGet("/cate")
      .then((res) => {
        setCategories(res.data.categories);
        if (res.data.categories.length > 0) {
          setFilterItemId(res.data.categories[0]?.id);
          setFilterItem(res.data.categories[0]?.name);
          setSubcategories(res.data.categories[0]?.subcategories || []);
          if (res.data.categories[0]?.subcategories.length > 0) {
            setFilterItemSubcategory(
              res.data.categories[0].subcategories[0]?.idsubcategory
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error al cargar categorías:", error);
      });
  }, []);

  useEffect(() => {
    if (filterItemId) {
      let selectedCategory = categories.find((cat) => cat.id === filterItemId);
      setSubcategories(selectedCategory?.subcategories || []);
      if (selectedCategory?.subcategories.length > 0) {
        setFilterItemSubcategory(
          selectedCategory.subcategories[0]?.idsubcategory
        );
      }
      setPage(1); // Resetear la paginación al cambiar la categoría
    }
  }, [filterItemId]);

  useEffect(() => {
    if (filterItemSubcategory !== null) {
      setPage(1); // Resetear la paginación al cambiar la subcategoría
    }
  }, [filterItemSubcategory]);

  useEffect(() => {
    if (filterItemId || filterItemSubcategory !== null) {
      ProductsByCategory({
        category: filterItemId,
        subcategory: filterItemSubcategory,
        page: page,
      });
    }
  }, [filterItemId, filterItemSubcategory, page]);

  const handlePageChange = (event, value) => {
    setPage(value); // Solo cambia la página sin modificar la categoría o subcategoría
  };

  return (
    <Box
      component='section'
      sx={{
        padding: theme.spacing(4),
        backgroundColor: "#FBFAFF",
      }}
      className='trending'
    >
      <Box className='trending-content' sx={{ marginBottom: theme.spacing(2) }}>
        <SectionTitle
          subTitle='¡Los más vendidos han llegado!'
          // title='Productos más vendidos'
          body='Descubre los productos más populares de la temporada y mantén viva la esencia del florecer junto a Merrycolor. ✨🌸'
        />
        <Grid container spacing={2} sx={{ mt: -5 }}>
          <Grid item xs={12}>
            <SearchProducts results={results} setResults={setResults} />
          </Grid>
        </Grid>
        {/* <Box
          className='tab-wrap trending-tabs'
          sx={{
            marginBottom: theme.spacing(4),
            display: "flex",
            justifyContent: "center",
            overflowX: isMobile ? "auto" : "visible",
            whiteSpace: isMobile ? "nowrap" : "normal",
          }}
        >
          <ButtonGroup
            orientation={isMobile ? "vertical" : "horizontal"}
            sx={{
              display: "flex",
              flexWrap: isMobile ? "nowrap" : "wrap",
              gap: isMobile ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            {categories.map((item) => (
              <Button
                key={item.id}
                size='large'
                sx={{
                  color: "#801F56",
                  "&:hover": {
                    backgroundColor: "#F0C9D5",
                    border: "1px solid #801F56",
                  },
                  border: "1px solid #801F56",
                  backgroundColor:
                    filterItem === item.name ? "#F0C9D5" : "transparent",
                  minWidth: isMobile ? "auto" : "100px",
                }}
                onClick={() => {
                  setFilterItem(item.name);
                  setFilterItemId(item.id);
                }}
              >
                {item.name}
              </Button>
            ))}
          </ButtonGroup>
        </Box> */}
        <CategorySelector
          categories={categories}
          filterItem={filterItem}
          setFilterItem={setFilterItem}
          setFilterItemId={setFilterItemId}
        />
        <Grid container spacing={2} sx={{ mt: 4 }}>
          {subcategories && subcategories.length > 0 && (
            <Typography fontWeight='bold'>Filtrar por subcategoría:</Typography>
          )}
          <Grid item xs={12} sx={{ mb: 4 }}>
            {/* {subcategories &&
              subcategories.map((sub) => (
                <Chip
                  key={sub.idsubcategory}
                  label={sub.subcategoryName}
                  style={{
                    margin: 2,
                    backgroundColor:
                      filterItemSubcategory === sub.idsubcategory
                        ? "#F0C9D5"
                        : "transparent",
                    color: "#801F56",
                    border: "2px solid #801F56",
                    fontSize: "15px",
                    padding: "5px 10px",
                    margin: 5,
                    display: "inline-block",
                    justifyContent: "center",
                  }}
                  variant='outlined'
                  onClick={() => setFilterItemSubcategory(sub.idsubcategory)}
                />
              ))} */}
            {subcategories && (
              <ScrollableSubcategories
                subcategories={subcategories}
                filterItemSubcategory={filterItemSubcategory}
                setFilterItemSubcategory={setFilterItemSubcategory}
              />
            )}
          </Grid>
        </Grid>

        {results.length === 0 && (
          <Box
            // className='shop'
            sx={{ marginTop: theme.spacing(1), backgroundColor: "#FBFAFF" }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                position: "relative",
                backgroundColor: "#FBFAFF",
              }}
            >
              {products && products.length > 0 ? (
                products.map((product, index) => (
                  <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={index}>
                    <CardProduct product={product} />
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%", // Asegurar que el div ocupe todo el ancho
                    height: "100%", // Ajustar si necesitas centrar verticalmente en un contenedor padre
                  }}
                >
                  <Typography
                    textAlign='center'
                    fontWeight='bold'
                    fontSize='20px'
                  >
                    No se encontraron productos con esta Subcategoría/Categoría
                  </Typography>
                </div>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: theme.spacing(2),
                }}
              >
                {total > 0 && (
                  <Pagination
                    count={Math.ceil(total / per_page)}
                    page={current_page}
                    onChange={handlePageChange}
                    color='secondary'
                    shape='rounded'
                    variant='outlined'
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CategoriesIndex;
