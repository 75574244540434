import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import Layout from "../../../components/Layout/Layout";
import { BackIcon } from "../../../components/icons/BackIcon";
import { Link } from "react-router-dom";

const ContactInfo = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    email: "correo@ejemplo.com",
    phone: "123-456-7890",
    address: "Calle Falsa 123",
    extras: "Información adicional...",
    userName: "Yoana Mateo Crescencio",
    typeUser: "Administrador",
  });

  const [editInfo, setEditInfo] = useState({ ...contactInfo });

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setContactInfo(editInfo);
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setEditInfo({
      ...editInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout>
      <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton aria-label='' onClick={() => props.history.goBack()}>
          <BackIcon />
        </IconButton>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: "center", marginTop: 5 }}
      >
        <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
            <Typography variant='h4' gutterBottom>
              Información de Contacto
            </Typography>
            <Grid
              item
              xs={12}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              sx={{ justifyContent: "center" }}
            >
              {isEditing ? (
                <div>
                  <TextField
                    label='Usuario'
                    name='userName'
                    value={editInfo.userName}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    label='Correo electrónico'
                    name='email'
                    value={editInfo.email}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    label='Teléfono'
                    name='phone'
                    value={editInfo.phone}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    label='Dirección'
                    name='address'
                    value={editInfo.address}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    label='Tipo usuario'
                    name='typeUser'
                    value={editInfo.typeUser}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    label='Texto Alternativo de contacto'
                    name='extras'
                    value={editInfo.extras}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                    multiline
                    rows={3}
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSaveClick}
                  >
                    Guardar
                  </Button>
                </div>
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant='body1'>
                        <svg
                          height='45px'
                          // width='200px'
                          version='1.1'
                          id='_x36_'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'
                          fill='#000000'
                        >
                          <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                          <g
                            id='SVGRepo_tracerCarrier'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          ></g>
                          <g id='SVGRepo_iconCarrier'>
                            {" "}
                            <g>
                              {" "}
                              <polygon
                                style={{ fill: "#7D7D7E" }}
                                points='301.525,189.241 301.525,224.783 300.594,224.783 289.559,225.499 0,225.499 0,75.812 3.081,73.016 15.549,61.623 18.702,58.685 40.199,38.908 45.429,34.108 45.573,33.965 70.939,49.371 247.497,156.495 268.708,169.322 278.524,175.269 294.575,185.015 '
                              ></polygon>{" "}
                              <polygon
                                style={{ fill: "#F0BA7D" }}
                                points='256.025,178.135 246.279,184.943 230.515,195.906 187.952,225.499 33.391,333.054 33.319,333.126 19.275,342.871 11.895,348.03 10.032,349.32 7.094,351.326 5.159,352.687 0,356.27 0,0 1.003,0.716 19.992,13.972 70.939,49.371 237.179,165.023 246.279,171.399 '
                              ></polygon>{" "}
                              <polygon
                                style={{ fill: "#E5A864" }}
                                points='512,0.023 512,356.295 511.015,355.605 498.56,346.942 256.008,178.135 265.706,171.39 275.207,164.794 '
                              ></polygon>{" "}
                              <polygon
                                style={{ fill: "#C0874A" }}
                                points='506.781,356.295 5.235,356.295 5.579,356 18.428,345.416 31.622,334.536 32.458,333.846 33.394,333.059 58.058,312.727 58.156,312.628 237.153,164.991 256.008,149.434 274.912,164.991 454.303,313.023 454.549,313.22 478.524,333.01 479.853,334.093 '
                              ></polygon>{" "}
                              <polygon
                                style={{ fill: "#ECB168" }}
                                points='512,356.295 0.017,356.295 5.579,352.11 16.459,343.939 30.292,333.551 32.458,331.927 58.058,312.727 58.156,312.628 246.31,171.39 256.008,164.104 264.229,170.307 265.706,171.39 454.303,313.023 454.549,313.22 479.853,332.221 506.781,352.406 511.015,355.605 511.064,355.605 '
                              ></polygon>{" "}
                              <g>
                                {" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='13.391,39.267 13.391,63.916 8.858,61.623 0,57.109 0,32.388 1.339,33.033 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='13.391,84.482 13.391,109.131 0,102.252 0,77.603 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='13.391,129.696 13.391,154.346 0,147.467 0,122.817 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='13.391,174.91 13.391,199.56 0,192.681 0,167.96 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='13.391,220.125 13.391,244.774 0,237.895 0,213.246 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='13.391,265.34 13.391,289.989 0,283.11 0,258.461 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='13.391,310.554 13.391,335.204 0,328.254 0,303.675 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='13.398,355.754 13.398,356.295 0.024,356.295 0.024,348.862 0.944,349.353 7.454,352.702 '
                                ></polygon>{" "}
                              </g>{" "}
                              <g>
                                {" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='498.262,39.26 498.262,63.923 502.914,61.61 512,57.08 512,32.367 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='498.262,84.501 498.262,109.116 512,102.224 512,77.61 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='498.262,129.693 498.262,154.357 512,147.466 512,122.802 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='498.262,174.885 498.262,199.549 512,192.658 512,167.994 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='498.262,220.127 498.262,244.792 512,237.899 512,213.235 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='498.262,265.32 498.262,289.984 512,283.091 512,258.428 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='498.262,310.561 498.262,335.176 512,328.284 512,303.67 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='498.262,355.754 498.262,356.295 512,356.295 512,348.862 511.055,349.353 504.368,352.702 '
                                ></polygon>{" "}
                              </g>{" "}
                              <g>
                                {" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='507.64,342.856 493.036,356.295 456.774,356.295 471.378,342.856 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='441.123,342.856 426.519,356.295 390.202,356.295 404.805,342.856 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='374.55,342.856 359.947,356.295 323.684,356.295 338.289,342.856 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='308.033,342.856 293.429,356.295 257.167,356.295 271.772,342.856 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='241.516,342.856 226.912,356.295 190.65,356.295 205.254,342.856 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='174.999,342.856 160.395,356.295 124.133,356.295 138.737,342.856 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#E18D40" }}
                                  points='108.482,342.856 93.878,356.295 57.561,356.295 72.165,342.856 '
                                ></polygon>{" "}
                                <polygon
                                  style={{ fill: "#776A57" }}
                                  points='41.91,342.856 30.116,353.735 27.25,356.295 11.93,356.295 11.93,342.856 '
                                ></polygon>{" "}
                              </g>{" "}
                              <polygon
                                style={{ fill: "#C0874A" }}
                                points='511.977,0 511.977,5.733 510.257,7.094 296.868,183.152 294.575,185.015 289.487,189.241 281.462,195.835 255.953,216.901 230.515,195.906 222.49,189.241 215.109,183.223 40.199,38.908 15.477,18.488 0,5.733 0,0 '
                              ></polygon>{" "}
                              <polygon
                                style={{ fill: "#ECB168" }}
                                points='511.977,0 506.962,3.798 283.182,171.829 278.524,175.269 274.726,178.135 265.77,184.871 255.953,192.252 247.784,186.017 246.279,184.943 237.251,178.135 228.867,171.901 45.429,34.108 19.418,14.546 1.003,0.716 0.932,0.716 0,0 '
                              ></polygon>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                        <p>ventasmerrycolor@gmail.com</p>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant='body1'>
                        <svg
                          width='45px'
                          version='1.1'
                          id='Layer_1'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 392.558 392.558'
                          fill='#000000'
                        >
                          <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                          <g
                            id='SVGRepo_tracerCarrier'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          ></g>
                          <g id='SVGRepo_iconCarrier'>
                            {" "}
                            <path
                              style={{ fill: "#FFFFFF" }}
                              d='M72.841,26.186c-5.883-5.883-15.451-5.883-21.269,0l-8.857,8.857 C-2.73,80.489,30.95,191.034,116.284,276.432s195.814,118.949,241.325,73.503l8.857-8.857c5.883-5.883,5.883-15.451,0-21.333 l-77.253-77.253c-5.883-5.883-15.451-5.883-21.269,0l-35.297,35.297c-20.234,20.234-61.996,10.214-94.966-22.82 s-43.055-74.667-22.82-94.966l35.297-35.297c5.883-5.883,5.883-15.451,0-21.269L72.841,26.186z'
                            ></path>{" "}
                            <path
                              style={{ fill: "#56ACE0" }}
                              d='M300.332,348.642c-32.129,0-71.564-14.675-109.834-39.822c4.784,0.84,9.568,1.293,14.222,1.293 c17.261,0,32.129-5.883,43.248-16.937l30.578-30.578l67.75,67.749l-4.073,4.073C332.785,343.923,318.627,348.642,300.332,348.642 L300.332,348.642z M83.702,202.283c-15.515-23.725-27.216-47.903-33.745-70.853c-10.343-36.008-7.434-65.487,8.016-81.002 l4.073-4.073l67.685,67.685l-30.578,30.578C84.736,159.228,79.758,179.98,83.702,202.283z'
                            ></path>{" "}
                            <g>
                              {" "}
                              <path
                                style={{ fill: "#194F82" }}
                                d='M220.882,104.473h45.188c6.012,0,10.925-4.848,10.925-10.925c0-6.077-4.848-10.925-10.925-10.925 h-18.941l41.956-41.956c4.267-4.267,4.267-11.119,0-15.386l0,0c-4.267-4.267-11.119-4.267-15.386,0l-41.956,41.956V48.295 c0-6.012-4.848-10.925-10.925-10.925c-6.077,0-10.925,4.848-10.925,10.925v45.188C210.021,99.624,214.87,104.473,220.882,104.473z'
                              ></path>{" "}
                              <path
                                style={{ fill: "#194F82" }}
                                d='M314.296,121.992h18.941l-41.956,41.956c-4.267,4.267-4.267,11.119,0,15.386l0,0 c4.267,4.267,11.119,4.267,15.386,0l41.956-41.956v18.941c0,6.012,4.848,10.925,10.925,10.925s10.925-4.848,10.925-10.925v-45.188 c0-6.012-4.848-10.925-10.925-10.925H314.36c-6.012,0-10.925,4.848-10.925,10.925C303.435,117.143,308.284,121.992,314.296,121.992 z'
                              ></path>{" "}
                              <path
                                style={{ fill: "#194F82" }}
                                d='M381.851,304.36l-77.253-77.253c-14.352-14.352-37.689-14.352-52.105,0l-35.297,35.297 c-9.762,9.762-39.24,2.069-64.129-22.82s-32.582-54.368-22.82-64.129l35.297-35.297c14.352-14.352,14.352-37.689,0-52.105 L88.292,10.8C73.94-3.552,50.603-3.552,36.187,10.8l-8.986,8.792C-28.46,75.253,3.863,194.784,100.769,291.754 c96.97,96.905,216.436,129.228,272.162,73.568l8.857-8.857C396.138,342.113,396.138,318.776,381.851,304.36z M366.401,341.079 l-8.857,8.857c-45.446,45.446-155.992,11.766-241.39-73.503S-2.795,80.489,42.716,35.107l8.857-8.857 c5.883-5.883,15.451-5.883,21.269,0l17.907,17.907L58.554,76.352c-4.267,4.267-4.267,11.119,0,15.386s11.119,4.267,15.386,0 l32.194-32.194L124.3,77.709l-15.257,15.192c-4.267,4.267-4.267,11.119,0,15.386c4.267,4.267,11.119,4.267,15.386,0l15.192-15.192 l10.279,10.279c5.883,5.883,5.883,15.451,0,21.269l-35.297,35.297c-20.234,20.234-10.214,61.996,22.82,94.966 s74.731,43.055,94.966,22.82l35.297-35.297c5.883-5.883,15.451-5.883,21.269,0l77.253,77.253 C372.284,325.628,372.284,335.196,366.401,341.079z'
                              ></path>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                        <p>56-1839-1680</p>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant='body1'>
                        <svg
                          width='45px'
                          version='1.1'
                          id='Layer_1'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 392.558 392.558'
                          fill='#000000'
                        >
                          <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                          <g
                            id='SVGRepo_tracerCarrier'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          ></g>
                          <g id='SVGRepo_iconCarrier'>
                            {" "}
                            <path
                              style={{ fill: "#FFFFFF" }}
                              d='M72.841,26.186c-5.883-5.883-15.451-5.883-21.269,0l-8.857,8.857 C-2.73,80.489,30.95,191.034,116.284,276.432s195.814,118.949,241.325,73.503l8.857-8.857c5.883-5.883,5.883-15.451,0-21.333 l-77.253-77.253c-5.883-5.883-15.451-5.883-21.269,0l-35.297,35.297c-20.234,20.234-61.996,10.214-94.966-22.82 s-43.055-74.667-22.82-94.966l35.297-35.297c5.883-5.883,5.883-15.451,0-21.269L72.841,26.186z'
                            ></path>{" "}
                            <path
                              style={{ fill: "#56ACE0" }}
                              d='M300.332,348.642c-32.129,0-71.564-14.675-109.834-39.822c4.784,0.84,9.568,1.293,14.222,1.293 c17.261,0,32.129-5.883,43.248-16.937l30.578-30.578l67.75,67.749l-4.073,4.073C332.785,343.923,318.627,348.642,300.332,348.642 L300.332,348.642z M83.702,202.283c-15.515-23.725-27.216-47.903-33.745-70.853c-10.343-36.008-7.434-65.487,8.016-81.002 l4.073-4.073l67.685,67.685l-30.578,30.578C84.736,159.228,79.758,179.98,83.702,202.283z'
                            ></path>{" "}
                            <g>
                              {" "}
                              <path
                                style={{ fill: "#194F82" }}
                                d='M220.882,104.473h45.188c6.012,0,10.925-4.848,10.925-10.925c0-6.077-4.848-10.925-10.925-10.925 h-18.941l41.956-41.956c4.267-4.267,4.267-11.119,0-15.386l0,0c-4.267-4.267-11.119-4.267-15.386,0l-41.956,41.956V48.295 c0-6.012-4.848-10.925-10.925-10.925c-6.077,0-10.925,4.848-10.925,10.925v45.188C210.021,99.624,214.87,104.473,220.882,104.473z'
                              ></path>{" "}
                              <path
                                style={{ fill: "#194F82" }}
                                d='M314.296,121.992h18.941l-41.956,41.956c-4.267,4.267-4.267,11.119,0,15.386l0,0 c4.267,4.267,11.119,4.267,15.386,0l41.956-41.956v18.941c0,6.012,4.848,10.925,10.925,10.925s10.925-4.848,10.925-10.925v-45.188 c0-6.012-4.848-10.925-10.925-10.925H314.36c-6.012,0-10.925,4.848-10.925,10.925C303.435,117.143,308.284,121.992,314.296,121.992 z'
                              ></path>{" "}
                              <path
                                style={{ fill: "#194F82" }}
                                d='M381.851,304.36l-77.253-77.253c-14.352-14.352-37.689-14.352-52.105,0l-35.297,35.297 c-9.762,9.762-39.24,2.069-64.129-22.82s-32.582-54.368-22.82-64.129l35.297-35.297c14.352-14.352,14.352-37.689,0-52.105 L88.292,10.8C73.94-3.552,50.603-3.552,36.187,10.8l-8.986,8.792C-28.46,75.253,3.863,194.784,100.769,291.754 c96.97,96.905,216.436,129.228,272.162,73.568l8.857-8.857C396.138,342.113,396.138,318.776,381.851,304.36z M366.401,341.079 l-8.857,8.857c-45.446,45.446-155.992,11.766-241.39-73.503S-2.795,80.489,42.716,35.107l8.857-8.857 c5.883-5.883,15.451-5.883,21.269,0l17.907,17.907L58.554,76.352c-4.267,4.267-4.267,11.119,0,15.386s11.119,4.267,15.386,0 l32.194-32.194L124.3,77.709l-15.257,15.192c-4.267,4.267-4.267,11.119,0,15.386c4.267,4.267,11.119,4.267,15.386,0l15.192-15.192 l10.279,10.279c5.883,5.883,5.883,15.451,0,21.269l-35.297,35.297c-20.234,20.234-10.214,61.996,22.82,94.966 s74.731,43.055,94.966,22.82l35.297-35.297c5.883-5.883,15.451-5.883,21.269,0l77.253,77.253 C372.284,325.628,372.284,335.196,366.401,341.079z'
                              ></path>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                        <p>55-3407-1275</p>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant='body1'>
                        <a
                          style={{ textDecoration: "none" }}
                          href='https://www.facebook.com/profile.php?id=100068529635739'
                          target='_blank'
                        >
                          <svg
                            viewBox='0 0 48 48'
                            width='45px'
                            version='1.1'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='#000000'
                          >
                            <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                            <g
                              id='SVGRepo_tracerCarrier'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></g>
                            <g id='SVGRepo_iconCarrier'>
                              {" "}
                              <title>Facebook-color</title>{" "}
                              <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                              <g
                                id='Icons'
                                stroke='none'
                                strokeWidth='1'
                                fill='none'
                                fillRule='evenodd'
                              >
                                {" "}
                                <g
                                  id='Color-'
                                  transform='translate(-200.000000, -160.000000)'
                                  fill='#4460A0'
                                >
                                  {" "}
                                  <path
                                    d='M225.638355,208 L202.649232,208 C201.185673,208 200,206.813592 200,205.350603 L200,162.649211 C200,161.18585 201.185859,160 202.649232,160 L245.350955,160 C246.813955,160 248,161.18585 248,162.649211 L248,205.350603 C248,206.813778 246.813769,208 245.350955,208 L233.119305,208 L233.119305,189.411755 L239.358521,189.411755 L240.292755,182.167586 L233.119305,182.167586 L233.119305,177.542641 C233.119305,175.445287 233.701712,174.01601 236.70929,174.01601 L240.545311,174.014333 L240.545311,167.535091 C239.881886,167.446808 237.604784,167.24957 234.955552,167.24957 C229.424834,167.24957 225.638355,170.625526 225.638355,176.825209 L225.638355,182.167586 L219.383122,182.167586 L219.383122,189.411755 L225.638355,189.411755 L225.638355,208 L225.638355,208 Z'
                                    id='Facebook'
                                  >
                                    {" "}
                                  </path>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                          <p>@Merry Color</p>
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant='body1'>
                        <a
                          style={{ textDecoration: "none" }}
                          href='https://www.instagram.com/merrycoloroficial/'
                          target='_blank'
                        >
                          <svg
                            viewBox='0 0 32 32'
                            width='45px'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                            <g
                              id='SVGRepo_tracerCarrier'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></g>
                            <g id='SVGRepo_iconCarrier'>
                              {" "}
                              <rect
                                x='2'
                                y='2'
                                width='28'
                                height='28'
                                rx='6'
                                fill='url(#paint0_radial_87_7153)'
                              ></rect>{" "}
                              <rect
                                x='2'
                                y='2'
                                width='28'
                                height='28'
                                rx='6'
                                fill='url(#paint1_radial_87_7153)'
                              ></rect>{" "}
                              <rect
                                x='2'
                                y='2'
                                width='28'
                                height='28'
                                rx='6'
                                fill='url(#paint2_radial_87_7153)'
                              ></rect>{" "}
                              <path
                                d='M23 10.5C23 11.3284 22.3284 12 21.5 12C20.6716 12 20 11.3284 20 10.5C20 9.67157 20.6716 9 21.5 9C22.3284 9 23 9.67157 23 10.5Z'
                                fill='white'
                              ></path>{" "}
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21ZM16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z'
                                fill='white'
                              ></path>{" "}
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M6 15.6C6 12.2397 6 10.5595 6.65396 9.27606C7.2292 8.14708 8.14708 7.2292 9.27606 6.65396C10.5595 6 12.2397 6 15.6 6H16.4C19.7603 6 21.4405 6 22.7239 6.65396C23.8529 7.2292 24.7708 8.14708 25.346 9.27606C26 10.5595 26 12.2397 26 15.6V16.4C26 19.7603 26 21.4405 25.346 22.7239C24.7708 23.8529 23.8529 24.7708 22.7239 25.346C21.4405 26 19.7603 26 16.4 26H15.6C12.2397 26 10.5595 26 9.27606 25.346C8.14708 24.7708 7.2292 23.8529 6.65396 22.7239C6 21.4405 6 19.7603 6 16.4V15.6ZM15.6 8H16.4C18.1132 8 19.2777 8.00156 20.1779 8.0751C21.0548 8.14674 21.5032 8.27659 21.816 8.43597C22.5686 8.81947 23.1805 9.43139 23.564 10.184C23.7234 10.4968 23.8533 10.9452 23.9249 11.8221C23.9984 12.7223 24 13.8868 24 15.6V16.4C24 18.1132 23.9984 19.2777 23.9249 20.1779C23.8533 21.0548 23.7234 21.5032 23.564 21.816C23.1805 22.5686 22.5686 23.1805 21.816 23.564C21.5032 23.7234 21.0548 23.8533 20.1779 23.9249C19.2777 23.9984 18.1132 24 16.4 24H15.6C13.8868 24 12.7223 23.9984 11.8221 23.9249C10.9452 23.8533 10.4968 23.7234 10.184 23.564C9.43139 23.1805 8.81947 22.5686 8.43597 21.816C8.27659 21.5032 8.14674 21.0548 8.0751 20.1779C8.00156 19.2777 8 18.1132 8 16.4V15.6C8 13.8868 8.00156 12.7223 8.0751 11.8221C8.14674 10.9452 8.27659 10.4968 8.43597 10.184C8.81947 9.43139 9.43139 8.81947 10.184 8.43597C10.4968 8.27659 10.9452 8.14674 11.8221 8.0751C12.7223 8.00156 13.8868 8 15.6 8Z'
                                fill='white'
                              ></path>{" "}
                              <defs>
                                {" "}
                                <radialGradient
                                  id='paint0_radial_87_7153'
                                  cx='0'
                                  cy='0'
                                  r='1'
                                  gradientUnits='userSpaceOnUse'
                                  gradientTransform='translate(12 23) rotate(-55.3758) scale(25.5196)'
                                >
                                  {" "}
                                  <stop stopColor='#B13589'></stop>{" "}
                                  <stop
                                    offset='0.79309'
                                    stopColor='#C62F94'
                                  ></stop>{" "}
                                  <stop offset='1' stopColor='#8A3AC8'></stop>{" "}
                                </radialGradient>{" "}
                                <radialGradient
                                  id='paint1_radial_87_7153'
                                  cx='0'
                                  cy='0'
                                  r='1'
                                  gradientUnits='userSpaceOnUse'
                                  gradientTransform='translate(11 31) rotate(-65.1363) scale(22.5942)'
                                >
                                  {" "}
                                  <stop stopColor='#E0E8B7'></stop>{" "}
                                  <stop
                                    offset='0.444662'
                                    stopColor='#FB8A2E'
                                  ></stop>{" "}
                                  <stop
                                    offset='0.71474'
                                    stopColor='#E2425C'
                                  ></stop>{" "}
                                  <stop
                                    offset='1'
                                    stopColor='#E2425C'
                                    stopOpacity='0'
                                  ></stop>{" "}
                                </radialGradient>{" "}
                                <radialGradient
                                  id='paint2_radial_87_7153'
                                  cx='0'
                                  cy='0'
                                  r='1'
                                  gradientUnits='userSpaceOnUse'
                                  gradientTransform='translate(0.500002 3) rotate(-8.1301) scale(38.8909 8.31836)'
                                >
                                  {" "}
                                  <stop
                                    offset='0.156701'
                                    stopColor='#406ADC'
                                  ></stop>{" "}
                                  <stop
                                    offset='0.467799'
                                    stopColor='#6A45BE'
                                  ></stop>{" "}
                                  <stop
                                    offset='1'
                                    stopColor='#6A45BE'
                                    stopOpacity='0'
                                  ></stop>{" "}
                                </radialGradient>{" "}
                              </defs>{" "}
                            </g>
                          </svg>
                          <p>@Merry Color Oficial </p>
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant='body1'>
                        <a
                          href='https://www.tiktok.com/@merrycolor.oficial?lang=es'
                          target='_blank'
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <svg
                            viewBox='62.370000000000005 70.49 675.3000000000001 675.3000000000001'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='#000000'
                            width='45px'
                          >
                            <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                            <g
                              id='SVGRepo_tracerCarrier'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></g>
                            <g id='SVGRepo_iconCarrier'>
                              {" "}
                              <g fill='#ee1d52'>
                                {" "}
                                <path d='M196 498.32l1.64 4.63c-.21-.53-.81-2.15-1.64-4.63zM260.9 393.39c2.88-24.88 12.66-38.81 31.09-53.09 26.37-19.34 59.31-8.4 59.31-8.4V267a135.84 135.84 0 0 1 23.94 1.48V352s-32.93-10.94-59.3 8.41c-18.42 14.27-28.22 28.21-31.09 53.09-.09 13.51 2.34 31.17 13.53 46.44q-4.15-2.22-8.46-5.06c-24.65-17.27-29.14-43.18-29.02-61.49zM511.25 147c-18.14-20.74-25-41.68-27.48-56.39h22.82s-4.55 38.57 28.61 76.5l.46.51A132.76 132.76 0 0 1 511.25 147zM621.18 205.8v81.84s-29.12-1.19-50.67-6.91c-30.09-8-49.43-20.27-49.43-20.27s-13.36-8.75-14.44-9.36v169c0 9.41-2.47 32.91-10 52.51-9.83 25.64-25 42.47-27.79 45.91 0 0-18.45 22.75-51 38.07-29.34 13.82-55.1 13.47-62.8 13.82 0 0-44.53 1.84-84.6-25.33a169.63 169.63 0 0 1-24.16-20.26l.2.15c40.08 27.17 84.6 25.33 84.6 25.33 7.71-.35 33.47 0 62.8-13.82 32.52-15.32 51-38.07 51-38.07 2.76-3.44 18-20.27 27.79-45.92 7.51-19.59 10-43.1 10-52.51V231c1.08.62 14.43 9.37 14.43 9.37s19.35 12.28 49.44 20.27c21.56 5.72 50.67 6.91 50.67 6.91v-64.13c9.96 2.33 18.45 2.96 23.96 2.38z'></path>{" "}
                              </g>{" "}
                              <path d='M597.23 203.42v64.11s-29.11-1.19-50.67-6.91c-30.09-8-49.44-20.27-49.44-20.27s-13.35-8.75-14.43-9.37V400c0 9.41-2.47 32.92-10 52.51-9.83 25.65-25 42.48-27.79 45.92 0 0-18.46 22.75-51 38.07-29.33 13.82-55.09 13.47-62.8 13.82 0 0-44.52 1.84-84.6-25.33l-.2-.15a157.5 157.5 0 0 1-11.93-13.52c-12.79-16.27-20.63-35.51-22.6-41a.24.24 0 0 1 0-.07c-3.17-9.54-9.83-32.45-8.92-54.64 1.61-39.15 14.81-63.18 18.3-69.2A162.84 162.84 0 0 1 256.68 303a148.37 148.37 0 0 1 42.22-25 141.61 141.61 0 0 1 52.4-11v64.9s-32.94-10.9-59.3 8.4c-18.43 14.28-28.21 28.21-31.09 53.09-.12 18.31 4.37 44.22 29 61.5q4.31 2.85 8.46 5.06a65.85 65.85 0 0 0 15.5 15.05c24.06 15.89 44.22 17 70 6.68C401.06 474.78 414 459.23 420 442c3.77-10.76 3.72-21.59 3.72-32.79V90.61h60c2.48 14.71 9.34 35.65 27.48 56.39a132.76 132.76 0 0 0 24.41 20.62c2.64 2.85 16.14 16.94 33.47 25.59a130.62 130.62 0 0 0 28.15 10.21z'></path>{" "}
                              <path
                                d='M187.89 450.39v.05l1.48 4.21c-.17-.49-.72-1.98-1.48-4.26z'
                                fill='#69c9d0'
                              ></path>{" "}
                              <path
                                d='M298.9 278a148.37 148.37 0 0 0-42.22 25 162.84 162.84 0 0 0-35.52 43.5c-3.49 6-16.69 30.05-18.3 69.2-.91 22.19 5.75 45.1 8.92 54.64a.24.24 0 0 0 0 .07c2 5.44 9.81 24.68 22.6 41a157.5 157.5 0 0 0 11.93 13.52 166.64 166.64 0 0 1-35.88-33.64c-12.68-16.13-20.5-35.17-22.54-40.79a1 1 0 0 1 0-.12v-.07c-3.18-9.53-9.86-32.45-8.93-54.67 1.61-39.15 14.81-63.18 18.3-69.2a162.68 162.68 0 0 1 35.52-43.5 148.13 148.13 0 0 1 42.22-25 144.63 144.63 0 0 1 29.78-8.75 148 148 0 0 1 46.57-.69V267a141.61 141.61 0 0 0-52.45 11z'
                                fill='#69c9d0'
                              ></path>{" "}
                              <path
                                d='M483.77 90.61h-60v318.61c0 11.2 0 22-3.72 32.79-6.06 17.22-18.95 32.77-36.13 39.67-25.79 10.36-45.95 9.21-70-6.68a65.85 65.85 0 0 1-15.54-15c20.49 10.93 38.83 10.74 61.55 1.62 17.17-6.9 30.08-22.45 36.12-39.68 3.78-10.76 3.73-21.59 3.73-32.78V70.49h82.85s-.93 7.92 1.14 20.12zM597.23 185.69v17.73a130.62 130.62 0 0 1-28.1-10.21c-17.33-8.65-30.83-22.74-33.47-25.59a93.69 93.69 0 0 0 9.52 5.48c21.07 10.52 41.82 13.66 52.05 12.59z'
                                fill='#69c9d0'
                              ></path>{" "}
                              <path
                                d='M486.85 701.51a22.75 22.75 0 0 1-1-6.73v-.16a24.53 24.53 0 0 0 1 6.89zM536.44 694.62v.16a23.07 23.07 0 0 1-1 6.73 24.89 24.89 0 0 0 1-6.89z'
                                fill='none'
                              ></path>{" "}
                              <path
                                d='M485.84 694.78a22.75 22.75 0 0 0 1 6.73 2.59 2.59 0 0 0 .14.45 25.28 25.28 0 0 0 24.16 17.8v25.59c-12.46 0-21.38.44-35-7.59-15.44-9.16-24.14-25.91-24.14-43.3 0-17.94 9.74-35.91 26.25-44.57 12-6.28 21.09-6.32 32.92-6.32v25.58a25.31 25.31 0 0 0-25.31 25.31z'
                                fill='#69c9d0'
                              ></path>{" "}
                              <path
                                d='M536.64 694.78a23.07 23.07 0 0 1-1 6.73c0 .15-.09.3-.14.45a25.3 25.3 0 0 1-24.16 17.8v25.59c12.45 0 21.38.44 34.95-7.59 15.49-9.16 24.21-25.91 24.21-43.3 0-17.94-9.74-35.91-26.25-44.57-12-6.28-21.09-6.32-32.91-6.32v25.58a25.31 25.31 0 0 1 25.3 25.31z'
                                fill='#ee1d52'
                              ></path>{" "}
                              <path d='M119.51 620.36h93.71l-8.66 25.78H180v98.67h-30.13v-98.67h-30.36zm248.35 0v25.78h30.36v98.67h30.17v-98.67h24.52l8.66-25.78zm-134.25 29.38A14.6 14.6 0 1 0 219 635.15a14.59 14.59 0 0 0 14.61 14.59zM219 744.81h29.58v-84.75H219zM355 649h-34.6l-29.82 29.82v-58.36h-29.39l-.09 124.35h29.67v-32.4L300 704l28.8 40.77h31.72l-41.72-59.62zm283.77 36.17L674.94 649h-34.59l-29.83 29.82v-58.36h-29.38L581 744.81h29.68v-32.4L620 704l28.8 40.77h31.73zm-76.06 9.27c0 28.1-23.09 50.89-51.57 50.89s-51.57-22.79-51.57-50.89 23.09-50.89 51.57-50.89 51.57 22.8 51.57 50.91zm-26.27 0a25.3 25.3 0 1 0-25.3 25.3 25.3 25.3 0 0 0 25.3-25.28z'></path>{" "}
                            </g>
                          </svg>
                          <p>@MerryColor.Oficial</p>
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ContactInfo;
