import { DetailBlock } from "../DetailBlock";
import MapIcon from "../../icons/MapIcon";
import PhoneEmailIcon from "../../icons/PhoneEmailicon";
import StoreIcon from "../../icons/StoreIcon";
export const ContactDetailBlock = () => {
  const contactBlockinfoData = [
    {
      step: "Visítanos",
      title:
        "Calle José María Morelos, #673, Zona centro, Guadalajara,Jalisco C.P 44100",
      icon: (
        <svg
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          fill='#000000'
        >
          <g id='SVGRepo_bgCarrier' strokeWidth={0}></g>
          <g
            id='SVGRepo_tracerCarrier'
            stroke-linecp='round'
            strokeLinejoin='round'
          ></g>
          <g id='SVGRepo_iconCarrier'>
            {" "}
            <g transform='translate(0 -1028.4)'>
              {" "}
              <path d='m8 1030.4 8 1v19l-8-1z' fill='#ecf0f1'></path>{" "}
              <path d='m2 1031.4 6-1v19l-6 1z' fill='#bdc3c7'></path>{" "}
              <path d='m16 1031.4 6-1v19l-6 1z' fill='#bdc3c7'></path>{" "}
              <path d='m3 1032.4 5-1v17l-5 1z' fill='#27ae60'></path>{" "}
              <path d='m8 1031.4 8 1v17l-8-1z' fill='#2ecc71'></path>{" "}
              <path
                d='m13 1c-1.657 0-3 1.3432-3 3s1.343 3 3 3 3-1.3432 3-3-1.343-3-3-3zm0 2c0.552 0 1 0.4477 1 1s-0.448 1-1 1-1-0.4477-1-1 0.448-1 1-1z'
                transform='translate(0 1028.4)'
                fill='#c0392b'
              ></path>{" "}
              <path d='m21 1048.4-5 1v-17l5-1z' fill='#27ae60'></path>{" "}
              <path
                d='m5.6875 1031.8-2.3125 0.5 4.625 4.9v-2.9l-2.3125-2.5z'
                fill='#f39c12'
              ></path>{" "}
              <path d='m21 1046.4-5 1v-6l5-3z' fill='#f39c12'></path>{" "}
              <path d='m21 1048.4-5 1v-6l5-3z' fill='#2980b9'></path>{" "}
              <path d='m8 1042.4 8-1v6l-8-1z' fill='#f1c40f'></path>{" "}
              <path d='m8 1044.4 8-1v6l-8-1z' fill='#3498db'></path>{" "}
              <path d='m3 1045.4 5-3v4l-5 1z' fill='#f39c12'></path>{" "}
              <path d='m3 1047.4 5-3v4l-5 1z' fill='#2980b9'></path>{" "}
              <path
                d='m8 8.8008v-2.8985l4 8.6597h-1.469z'
                transform='translate(0 1028.4)'
                fill='#f1c40f'
              ></path>{" "}
              <path
                d='m13 1028.4c-2.209 0-4 1.8-4 4 0 0.7 0.1908 1.3 0.5156 1.9 0.0539 0.1 0.1105 0.2 0.1719 0.3l3.3125 5.8 3.312-5.8c0.051-0.1 0.095-0.2 0.141-0.2l0.031-0.1c0.325-0.6 0.516-1.2 0.516-1.9 0-2.2-1.791-4-4-4zm0 2c1.105 0 2 0.9 2 2s-0.895 2-2 2-2-0.9-2-2 0.895-2 2-2z'
                fill='#e74c3c'
              ></path>{" "}
            </g>{" "}
          </g>
        </svg>
      ),
    },
    {
      step: "Llámanos",
      title: "+52 56-1839-1680 \n merrycolorventas@gmail.com",
      icon: <PhoneEmailIcon />,
    },
    {
      step: "Atención Personalizada",
      title:
        "Lunes - Viernes: 8:00 AM - 7:00 PM \n Sabado: 9:00 AM - 6:00 PM \n \n     ",
      icon: <StoreIcon />,
    },
  ];

  const detailBlocks = [...contactBlockinfoData];
  return (
    <>
      {/* <!--  Contact DETAIL BLOCK --> */}
      <DetailBlock detailBlocks={detailBlocks} />
    </>
  );
};
