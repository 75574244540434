export const DeleteAccountIcon = ({
  fill = "currentColor",
  filled,
  size,
  height,
  width,
  label,
  ...props
}) => {
  return (
    <svg
      width={width}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      fill='#000000'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth={0}></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {" "}
        <path
          style={{ fill: "#FFC1A6" }}
          d='M196.084,8.169v232.035c58.063,0,105.124-47.061,105.124-105.124v-21.787 C301.208,55.241,254.148,8.169,196.084,8.169z'
        ></path>{" "}
        <path
          style={{ fill: "#FFDBCC" }}
          d='M196.084,8.169c40.012,0,72.443,47.071,72.443,105.124v21.787c0,58.063-32.43,105.124-72.443,105.124 c-58.063,0-105.124-47.061-105.124-105.124v-21.787C90.961,55.241,138.021,8.169,196.084,8.169z'
        ></path>{" "}
        <path
          style={{ fill: "#FEDC3D" }}
          d='M197.946,272.351l142.478,177.011h32.681C373.106,352.212,294.856,273.353,197.946,272.351z'
        ></path>{" "}
        <path
          style={{ fill: "#FFE777" }}
          d='M197.947,272.351c78.859,1.231,142.478,80.003,142.478,177.011H19.063 c0-97.77,79.251-177.022,177.022-177.022C196.705,272.34,197.326,272.34,197.947,272.351z'
        ></path>{" "}
        <path
          style={{ fill: "#D5F6F5" }}
          d='M217.872,273.669l-21.787,61.68c30.077,0,54.457-24.369,54.468-54.457 C240.05,277.493,229.125,275.052,217.872,273.669z'
        ></path>{" "}
        <path
          style={{ fill: "#FFFFFF" }}
          d='M217.872,273.669v7.212c0,30.088-9.75,54.468-21.787,54.468c-30.077,0-54.468-24.38-54.468-54.468 c17.157-5.545,35.459-8.541,54.468-8.541C203.459,272.34,210.725,272.787,217.872,273.669z'
        ></path>{" "}
        <path
          style={{ fill: "#FE5F1A" }}
          d='M384,285.957V503.83c60.166,0,108.936-48.771,108.936-108.936S444.164,285.957,384,285.957z'
        ></path>{" "}
        <path
          style={{ fill: "#FE834D" }}
          d='M384,285.957c42.115,0,76.255,48.771,76.255,108.936S426.115,503.83,384,503.83 c-60.166,0-108.936-48.771-108.936-108.936S323.834,285.957,384,285.957z'
        ></path>{" "}
        <path
          style={{ fill: "#FFFFFF" }}
          d='M434.068,421.854l-26.961-26.961l26.961-26.961c6.382-6.382,6.382-16.727,0-23.109 s-16.727-6.382-23.109,0L384,371.785l-26.961-26.961c-6.382-6.381-16.727-6.381-23.109,0c-6.382,6.382-6.382,16.727,0,23.109 l26.961,26.961l-26.961,26.961c-6.382,6.382-6.382,16.727,0,23.109s16.727,6.382,23.109,0L384,418.002l26.961,26.961 c6.382,6.382,16.727,6.382,23.109,0C440.451,438.581,440.449,428.236,434.068,421.854z'
        ></path>{" "}
        <path d='M236.391,131.469c4.512,0,8.17-3.658,8.17-8.17v-16.34c0-4.512-3.658-8.17-8.17-8.17c-4.512,0-8.17,3.658-8.17,8.17v16.34 C228.221,127.811,231.878,131.469,236.391,131.469z'></path>{" "}
        <path d='M155.778,131.469c4.512,0,8.17-3.658,8.17-8.17v-16.34c0-4.512-3.658-8.17-8.17-8.17s-8.17,3.658-8.17,8.17v16.34 C147.608,127.811,151.265,131.469,155.778,131.469z'></path>{" "}
        <path d='M177.696,182.008c4.369,1.131,8.827-1.495,9.957-5.862c0.998-3.856,4.465-6.549,8.431-6.549s7.433,2.693,8.431,6.549 c0.953,3.682,4.269,6.125,7.903,6.125c0.678,0,1.366-0.085,2.053-0.263c4.368-1.131,6.993-5.588,5.862-9.957 c-2.864-11.066-12.836-18.795-24.25-18.795s-21.386,7.729-24.25,18.795C170.703,176.419,173.328,180.878,177.696,182.008z'></path>{" "}
        <path d='M253.281,441.19H27.428c3.248-67.847,46.734-125.316,107.053-149.036c5.326,29.175,30.913,51.369,61.604,51.369 c30.693,0,56.282-22.197,61.605-51.377c7.443,2.912,14.69,6.337,21.661,10.296c3.923,2.229,8.911,0.854,11.14-3.069 c2.229-3.923,0.855-8.911-3.069-11.14c-27.716-15.743-59.3-24.064-91.336-24.064c-102.115,0-185.192,83.077-185.192,185.192 c0,4.512,3.658,8.17,8.17,8.17h234.219c4.512,0,8.17-3.658,8.17-8.17S257.794,441.19,253.281,441.19z M241.982,286.875 c-2.949,22.707-22.401,40.306-45.897,40.306c-23.503,0-42.958-17.608-45.899-40.324c14.601-4.13,29.995-6.349,45.899-6.349 C211.661,280.509,227.114,282.687,241.982,286.875z'></path>{" "}
        <path d='M196.084,248.375c62.471,0,113.294-50.823,113.294-113.294v-21.787C309.378,50.823,258.554,0,196.084,0 S82.791,50.823,82.791,113.294v21.787C82.791,197.552,133.614,248.375,196.084,248.375z M99.131,113.294 c0-53.461,43.493-96.953,96.953-96.953s96.953,43.493,96.953,96.953v21.787c0,53.461-43.493,96.953-96.953,96.953 s-96.953-43.493-96.953-96.953V113.294z'></path>{" "}
        <path d='M384,277.787c-64.573,0-117.107,52.533-117.107,117.107S319.427,512,384,512s117.107-52.533,117.107-117.107 S448.572,277.787,384,277.787z M384,495.66c-55.563,0-100.766-45.203-100.766-100.766S328.437,294.127,384,294.127 s100.766,45.203,100.766,100.766S439.562,495.66,384,495.66z'></path>{" "}
        <path d='M447.025,356.379c0-6.547-2.549-12.702-7.179-17.332s-10.785-7.179-17.332-7.179s-12.702,2.549-17.332,7.179L384,360.23 l-21.183-21.183c-4.63-4.63-10.785-7.179-17.332-7.179s-12.703,2.549-17.332,7.179c-4.63,4.63-7.179,10.785-7.179,17.332 s2.55,12.703,7.179,17.332l21.183,21.184l-21.183,21.183c-9.557,9.557-9.557,25.107,0,34.664c4.63,4.63,10.785,7.179,17.332,7.179 s12.702-2.549,17.332-7.179L384,429.558l21.183,21.183c4.63,4.63,10.785,7.179,17.332,7.179s12.703-2.549,17.332-7.179 c9.557-9.557,9.557-25.107,0-34.664l-21.183-21.183l21.183-21.183C444.475,369.081,447.025,362.926,447.025,356.379z M428.291,362.156l-26.961,26.961c-3.191,3.191-3.191,8.364,0,11.554l26.961,26.961c3.185,3.185,3.185,8.37,0,11.555 c-1.544,1.544-3.595,2.393-5.778,2.393c-2.182,0-4.234-0.85-5.777-2.393l-26.961-26.961c-1.532-1.533-3.61-2.393-5.777-2.393 s-4.245,0.861-5.777,2.393l-26.961,26.961c-1.544,1.544-3.595,2.393-5.777,2.393s-4.234-0.85-5.778-2.393 c-3.185-3.185-3.185-8.37,0-11.555l26.961-26.961c3.191-3.191,3.191-8.364,0-11.554l-26.961-26.962 c-1.544-1.543-2.393-3.594-2.393-5.777c0-2.182,0.85-4.234,2.393-5.777c1.544-1.543,3.595-2.393,5.778-2.393 c2.182,0,4.234,0.85,5.777,2.393l26.961,26.961c3.191,3.191,8.364,3.191,11.554,0l26.961-26.961 c1.544-1.544,3.595-2.393,5.777-2.393s4.234,0.85,5.778,2.393s2.393,3.595,2.393,5.777 C430.684,358.561,429.835,360.612,428.291,362.156z'></path>{" "}
      </g>
    </svg>
  );
};
