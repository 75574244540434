import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
const slides = [
  {
    title: (
      <Typography
        variant='h2'
        sx={{
          fontWeight: "bold",
          letterSpacing: 2,
          mb: 2,
          fontFamily: "roboto",
          color: "#801F56",
        }}
      >
        MerryColor
      </Typography>
    ),
    subtitle: (
      <Typography
        variant='h4'
        sx={{
          fontWeight: "bold",
          mb: 4,
          fontFamily: "roboto",
          color: "#801F56",
        }}
      >
        El arte de florecer
      </Typography>
    ),
    image: require("../../assets/img/main-bg.jpg"),
  },
  {
    title: (
      <Typography
        variant='h2'
        sx={{
          fontWeight: "bold",
          letterSpacing: 2,
          mb: 2,
          fontFamily: "roboto",
          color: "#801F56",
        }}
      >
        Belleza Natural
      </Typography>
    ),
    subtitle: (
      <Typography
        variant='h4'
        sx={{
          fontWeight: "bold",
          mb: 4,
          fontFamily: "roboto",
          color: "#801F56",
        }}
      >
        Colores que inspiran
      </Typography>
    ),
    image: require("../../assets/img/nails1.jpg"),
  },
  // {
  //   title: (
  //     <Typography
  //       variant='h2'
  //       sx={{
  //         fontWeight: "bold",
  //         letterSpacing: 2,
  //         mb: 2,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       Vive en color
  //     </Typography>
  //   ),
  //   subtitle: (
  //     <Typography
  //       variant='h4'
  //       sx={{
  //         fontWeight: "bold",
  //         mb: 4,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       Expresa tu escencia
  //     </Typography>
  //   ),
  //   image: require("../../assets/img/nails-3.jpg"),
  // },
  // {
  //   title: (
  //     <Typography
  //       variant='h2'
  //       sx={{
  //         fontWeight: "bold",
  //         letterSpacing: 2,
  //         mb: 2,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       Tus uñas tu lienzo
  //     </Typography>
  //   ),
  //   subtitle: (
  //     <Typography
  //       variant='h4'
  //       sx={{
  //         fontWeight: "bold",
  //         mb: 4,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       Deja que el color cuente tu historia
  //     </Typography>
  //   ),
  //   image: require("../../assets/img/nails-4.jpg"),
  // },
  // {
  //   title: (
  //     <Typography
  //       variant='h2'
  //       sx={{
  //         fontWeight: "bold",
  //         letterSpacing: 2,
  //         mb: 2,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       Cada tono es una emoción
  //     </Typography>
  //   ),
  //   subtitle: (
  //     <Typography
  //       variant='h4'
  //       sx={{
  //         fontWeight: "bold",
  //         mb: 4,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       Cada diseño una expresión
  //     </Typography>
  //   ),
  //   image: require("../../assets/img/nails-5.jpg"),
  // },
  // {
  //   title: (
  //     <Typography
  //       variant='h2'
  //       sx={{
  //         fontWeight: "bold",
  //         letterSpacing: 2,
  //         mb: 2,
  //         fontFamily: "roboto",
  //         color: "white",
  //       }}
  //     >
  //       La belleza empieza en las manos
  //     </Typography>
  //   ),
  //   subtitle: (
  //     <Typography
  //       variant='h4'
  //       sx={{
  //         fontWeight: "bold",
  //         mb: 4,
  //         fontFamily: "roboto",
  //         color: "white",
  //       }}
  //     >
  //       ¡Dale color a tu vida!
  //     </Typography>
  //   ),
  //   image: require("../../assets/img/nails-6.jpg"),
  // },
  // {
  //   title: (
  //     <Typography
  //       variant='h2'
  //       sx={{
  //         fontWeight: "bold",
  //         letterSpacing: 2,
  //         mb: 2,
  //         fontFamily: "roboto",
  //         color: "white",
  //       }}
  //     >
  //       MerryColor
  //     </Typography>
  //   ),
  //   subtitle: (
  //     <Typography
  //       variant='h4'
  //       sx={{
  //         fontWeight: "bold",
  //         mb: 4,
  //         fontFamily: "roboto",
  //         color: "white",
  //       }}
  //     >
  //       Donde el arte y la elegancia se encuentran
  //     </Typography>
  //   ),
  //   image: require("../../assets/img/nails-11.jpg"),
  // },
  // {
  //   title: (
  //     <Typography
  //       variant='h2'
  //       sx={{
  //         fontWeight: "bold",
  //         letterSpacing: 2,
  //         mb: 2,
  //         mr: 15,
  //         fontFamily: "roboto",
  //         color: "white",
  //       }}
  //     >
  //       Tus uñas hablan de ti
  //     </Typography>
  //   ),
  //   subtitle: (
  //     <Typography
  //       variant='h4'
  //       sx={{
  //         fontWeight: "bold",
  //         mb: 4,
  //         mr: 15,
  //         fontFamily: "roboto",
  //         color: "white",
  //       }}
  //     >
  //       ¡Haz que cuenten una gran historia!
  //     </Typography>
  //   ),
  //   image: require("../../assets/img/nails-8.jpg"),
  // },
  // {
  //   title: (
  //     <Typography
  //       variant='h2'
  //       sx={{
  //         fontWeight: "bold",
  //         letterSpacing: 2,
  //         mb: 2,
  //         mr: 15,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       Tu creatividad no tiene límites...
  //     </Typography>
  //   ),
  //   subtitle: (
  //     <Typography
  //       variant='h4'
  //       sx={{
  //         fontWeight: "bold",
  //         mb: 4,
  //         mr: 15,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       y MerryColor lo sabe
  //     </Typography>
  //   ),
  //   image: require("../../assets/img/nails-12.jpg"),
  // },
  // {
  //   title: (
  //     <Typography
  //       variant='h2'
  //       sx={{
  //         fontWeight: "bold",
  //         letterSpacing: 2,
  //         mb: 2,
  //         mr: 15,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       Porque cada detalle cuenta
  //     </Typography>
  //   ),
  //   subtitle: (
  //     <Typography
  //       variant='h4'
  //       sx={{
  //         fontWeight: "bold",
  //         mb: 4,
  //         mr: 15,
  //         fontFamily: "roboto",
  //         color: "#801F56",
  //       }}
  //     >
  //       y tus uñas lo merecen
  //     </Typography>
  //   ),
  //   image: require("../../assets/img/nails-13.jpg"),
  // },
];
export const Banner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* Swiper para cambiar imágenes */}
      <Swiper
        modules={[Autoplay]}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        loop={true}
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
        style={{ width: "100%", height: "100%" }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                backgroundImage: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100vh",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Texto superpuesto */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "30%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "white",
          zIndex: 10,
          maxWidth: "600px",
        }}
      >
        {slides[activeIndex].title}

        {slides[activeIndex].subtitle}
      </Box>
    </Box>
  );
};
