import React, { useContext, useEffect, useState } from "react";
import PedidosContext from "../../../../Context/Pedidos/PedidosContext";
import Card from "../Card/Card";
import {
  Alert,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const ValidatePayment = () => {
  const [active, setActive] = useState(-1);
  const handleCollapse = (indx) => {
    if (active === indx) {
      setActive(-1);
    } else {
      setActive(indx);
    }
  };
  const { orders_client, GetValidandoPago } = useContext(PedidosContext);
  useEffect(() => {
    GetValidandoPago();
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      {orders_client.length > 0 ? (
        <Grid container spacing={2}>
          {/* Contenido */}
          {orders_client.map((order, index) => (
            <Grid item xs={12} key={index}>
              <Card
                key={index}
                index={index}
                onCollapse={handleCollapse}
                order={order}
                active={active}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity='info' sx={{ mt: 2 }}>
          No tienes pedidos en validacion de pago.
        </Alert>
      )}
    </Box>
  );
};

export default ValidatePayment;
