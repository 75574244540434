import { useContext, useState } from "react";
import AuthContext from "../../Context/auth/AuthContext";
import { ProfileOrders } from "./ProfileOrders/ProfileOrders";
import ProfileDirections from "./ProfileDirections/ProfileDirections";
import DeleteAccount from "./ProfileActions/DeleteAccount";

import {
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import PaymentInfo from "./PaymentInfo";

export const Profile = () => {
  const { cerrarSesion } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("orders");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {/* <!-- BEGIN PROFILE --> */}
      <Box sx={{ backgroundColor: "#f9f9f9", minHeight: "100vh", p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: 2,
          }}
        >
          <Button
            variant='contained'
            sx={{
              backgroundColor: "#D8708E",
              color: "#fff",
              "&:hover": { backgroundColor: "#D8708E", color: "#fff" },
            }}
            onClick={cerrarSesion}
          >
            Cerrar Sesión
          </Button>
        </Box>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor='secondary'
              textColor='secondary'
              // centered
              variant='scrollable'
            >
              <Tab
                label='Mi perfil'
                value='myInfo'
                sx={{ textTransform: "none", fontWeight: "bold" }}
              />
              <Tab
                label='Mis compras'
                value='orders'
                sx={{ textTransform: "none", fontWeight: "bold" }}
              />
              <Tab
                label='Direcciones de envío'
                value='wishList'
                sx={{ textTransform: "none", fontWeight: "bold" }}
              />
              <Tab
                label='Datos de pago'
                value='payments'
                sx={{ textTransform: "none", fontWeight: "bold" }}
              />
            </Tabs>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ mt: 2 }}>
            {activeTab === "myInfo" && <DeleteAccount />}
            {activeTab === "orders" && <ProfileOrders />}
            {activeTab === "wishList" && <ProfileDirections />}
            {activeTab === "payments" && <PaymentInfo />}
          </Box>
        </Paper>
      </Box>
      {/* <!-- PROFILE EOF   --> */}
    </>
  );
};
