import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import React from "react";

export const Breadcrumb = ({ breadcrumb, title, description }) => {
  const location = useLocation();

  return (
    <>
      {/* <!-- BEGIN DETAIL MAIN BLOCK --> */}
      <div className='detail-block detail-block_margin'>
        <div className='wrapper'>
          <div className='detail-block__content'>
            <h1>{title}</h1>

            {breadcrumb && (
              <Breadcrumbs aria-label='breadcrumb' className='bread-crumbs'>
                {breadcrumb.map(({ path, label }, i) => (
                  <React.Fragment key={i}>
                    {path === location.pathname ? (
                      <Typography className='bread-crumbs__item'>
                        {label}
                      </Typography>
                    ) : (
                      <Link
                        to={path}
                        className='bread-crumbs__link'
                        component={Link}
                        underline='hover'
                        color='inherit'
                      >
                        {label}
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </Breadcrumbs>
            )}

            {/* IF NEED DESCRIPTION */}
            {description && <span className='error-descr'>{description}</span>}
          </div>
        </div>
      </div>
      {/* <!-- DETAIL MAIN BLOCK EOF   --> */}
    </>
  );
};
