import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, TextField, Button, Chip } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import DashboardContext from "../../Context/Dashboard/DashboardContext";
const ReportSalesSeller = () => {
  const { downloadExcelSalesReportSeller } = useContext(DashboardContext);
  const [fecha_inicio, setStartDate] = useState(null);
  const [fecha_fin, setEndDate] = useState(null);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue.format("YYYY-MM-DD")); // Convertir la fecha a ISO string en UTC
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue.format("YYYY-MM-DD")); // Convertir la fecha a ISO string en UTC
  };
  const handleClickDownload = () => {
    downloadExcelSalesReportSeller(fecha_inicio, fecha_fin);
  };
  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container>
          <Grid item xs={12} sm={4} padding={2}>
            <DatePicker
              sx={{ width: "100%" }}
              label='Desde'
              value={fecha_inicio ? dayjs(fecha_inicio) : null} // Convertir el string ISO a un objeto Day.js
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={4} padding={2}>
            <DatePicker
              sx={{ width: "100%" }}
              label='Hasta'
              value={fecha_fin ? dayjs(fecha_fin) : null} // Convertir el string ISO a un objeto Day.js
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={4} padding={2}>
            <Button
              variant='outlined'
              sx={{
                padding: 1,
                borderColor: "green",
                color: "green",
                "&:hover": {
                  borderColor: "green",
                  color: "green",
                },
              }}
              fullWidth
              onClick={() => handleClickDownload()}
              startIcon={
                <svg
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  width='35px'
                >
                  <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                  <g
                    id='SVGRepo_tracerCarrier'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  ></g>
                  <g id='SVGRepo_iconCarrier'>
                    {" "}
                    <path
                      d='M4 4C4 3.44772 4.44772 3 5 3H14H14.5858C14.851 3 15.1054 3.10536 15.2929 3.29289L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4Z'
                      stroke='#289a2a'
                      strokeWidth='2'
                      strokeLinecap='round'
                    ></path>{" "}
                    <path
                      d='M20 8H15V3'
                      stroke='#289a2a'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    ></path>{" "}
                    <path
                      d='M12 9L12 17'
                      stroke='#289a2a'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    ></path>{" "}
                    <path
                      d='M9 14L12 17L15 14'
                      stroke='#289a2a'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    ></path>{" "}
                  </g>
                </svg>
              }
            >
              Descargar
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default ReportSalesSeller;
