import React, { useContext, useEffect, useState } from "react";
import PedidosContext from "../../../../Context/Pedidos/PedidosContext";
import Card from "../Card/Card";
import {
  Alert,
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const ShippOrder = () => {
  const [active, setActive] = useState(-1);
  const handleCollapse = (index) => {
    setActive((prev) => (prev === index ? -1 : index));
  };

  const { orders_client, GetPedidosEnviados } = useContext(PedidosContext);

  useEffect(() => {
    GetPedidosEnviados();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ mt: 4 }}>
      {orders_client.length > 0 ? (
        <Grid container spacing={2}>
          {orders_client.map((order, index) => (
            <Grid item xs={12} key={index}>
              <Card
                key={index}
                index={index}
                onCollapse={handleCollapse}
                order={order}
                active={active}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity='info' sx={{ mt: 2 }}>
          No tienes pedidos enviados.
        </Alert>
      )}
    </Box>
  );
};

export default ShippOrder;
