import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import {
  PictureAsPdf as PdfIcon,
  UploadFile,
  RemoveRedEye,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import formatDate from "../../../../utils/FormatDate";
import FormatCurrency from "../../../../utils/FormatCurrency";
import ModalTicketPayment from "../../../Cart/ModalTicketPayment";
import OrdersContext from "../../../../Context/Orders/OrdersContext";
import DownloadIcon from "@mui/icons-material/Download";
const statusConfig = {
  0: { color: "warning.main", text: "Pendiente de pago" },
  1: { color: "info.main", text: "Validando Pago" },
  2: { color: "info.dark", text: "En preparación" },
  3: { color: "success.main", text: "Enviado" },
  4: { color: "error.main", text: "Cancelado" },
};

const Card = ({ order, index, onCollapse, active, downloadInvoice }) => {
  const { OrderInvoice, GetPdfInvoice } = useContext(OrdersContext);
  const {
    direction,
    status,
    id,
    subtotal,
    shipping_cost = 0,
    total,
    payments,
    aditional_discount = 0,
    business,
    created_at,
  } = order;

  const [modalState, setModalState] = useState({
    open: false,
    order_id: null,
    total: null,
    envio: null,
  });

  const handleModal = (open, id = null, total = null, envio = null) => {
    setModalState({ open, order_id: id, total, envio });
  };

  const totalPagado =
    payments?.reduce((sum, payment) => sum + payment.amount, 0) || 0;

  const deliveryAddress = order.direction
    ? [
        order.direction.street,
        order.direction.between_street,
        order.direction.number_ext,
        order.direction.number_int,
        order.direction.and_between_street,
        order.direction.city,
        order.direction.state?.name,
        order.direction.municipality?.name,
        order.direction.zone,
        order.direction.postal_code,
        order.direction.references,
        order.direction.email,
        order.direction.phone,
      ]
        .filter(Boolean)
        .join(", ")
    : "Dirección no disponible";

  return (
    <Paper
      elevation={3}
      sx={{
        mb: 2,
        p: 2,
        borderRadius: 5,
        border: "dotted #802257 2px",
        backgroundColor: active === index ? "rgba(0, 0, 255, 0.05)" : "white",
        cursor: "pointer",
      }}
      onClick={() => onCollapse(index)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography fontWeight='bold'>Fecha Compra:</Typography>
          <Typography>{formatDate(created_at)}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography fontWeight='bold'>Folio:</Typography>
          <Typography>#{id}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography fontWeight='bold'>Dirección de Envío:</Typography>
          <Typography variant='body2' textAlign='justify'>
            {deliveryAddress}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography fontWeight='bold'>Subtotal:</Typography>
          <Typography>{FormatCurrency(subtotal)}</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography fontWeight='bold'>Descuento:</Typography>
          <Typography>{business?.discount || 0} %</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography fontWeight='bold'>Desc. Adicional:</Typography>
          <Typography>{aditional_discount || 0} %</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography fontWeight='bold'>Costo de envío:</Typography>
          <Typography>{FormatCurrency(Number(shipping_cost))}</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography fontWeight='bold'>Total:</Typography>
          <Typography fontWeight='bold'>
            {FormatCurrency(total + Number(shipping_cost))}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography fontWeight='bold'>Status:</Typography>
          <Typography color={statusConfig[status].color}>
            {statusConfig[status].text}
          </Typography>
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='center' gap={1}>
          {total + shipping_cost !== totalPagado && (
            <Tooltip title='Subir comprobante de pago'>
              <Button
                sx={{
                  backgroundColor: "#802257",
                  color: "white",
                  "&:hover": { backgroundColor: "#802257", color: "white" },
                }}
                onClick={(e) => {
                  handleModal(true, id, total, shipping_cost);
                  e.stopPropagation();
                }}
                endIcon={<UploadFile />}
              >
                Subir comprobante
              </Button>
            </Tooltip>
          )}
          <Link to={`/Detalle-venta/${id}`}>
            <Tooltip title='Ver detalle de la venta'>
              <Button
                sx={{
                  backgroundColor: "#802257",
                  color: "white",
                  "&:hover": { backgroundColor: "#802257", color: "white" },
                }}
              >
                Detalle orden
              </Button>
            </Tooltip>
          </Link>
          {order.invoice === "si" &&
            order.invoiced_id === null &&
            order.status === 3 && (
              <Button
                onClick={() => OrderInvoice(order.id)}
                variant='contained'
                color='success'
              >
                Facturar
              </Button>
            )}
          {downloadInvoice &&
            order.invoice === "si" &&
            order.invoiced_id !== null &&
            order.status === 3 && (
              <Button
                onClick={() => GetPdfInvoice(order.id)}
                variant='contained'
                color='success'
                endIcon={<DownloadIcon />}
              >
                Descargar Factura
              </Button>
            )}
        </Grid>
      </Grid>
      {modalState.open && (
        <ModalTicketPayment
          {...modalState}
          handleClose={() => handleModal(false)}
          total_pagado={totalPagado}
        />
      )}
    </Paper>
  );
};

export default Card;
