import { useRef } from "react";
import { Box, IconButton, Chip } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const ScrollableSubcategories = ({
  subcategories,
  filterItemSubcategory,
  setFilterItemSubcategory,
}) => {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth } = scrollRef.current;
      const scrollAmount = clientWidth / 2;
      scrollRef.current.scrollTo({
        left:
          direction === "left"
            ? scrollLeft - scrollAmount
            : scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box display='flex' alignItems='center' width='100%'>
      <IconButton
        onClick={() => scroll("left")}
        sx={{ visibility: subcategories.length > 5 ? "visible" : "hidden" }}
      >
        <ArrowBackIos sx={{ color: "#801F56" }} />
      </IconButton>
      <Box
        ref={scrollRef}
        sx={{
          display: "flex",
          overflowX: "auto",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
          gap: 1,
          flexWrap: "nowrap",
          width: "100%",
          scrollBehavior: "smooth",
          padding: "8px 0",
        }}
      >
        {subcategories.map((sub) => (
          <Chip
            key={sub.idsubcategory}
            label={sub.subcategoryName}
            style={{
              margin: 2,
              backgroundColor:
                filterItemSubcategory === sub.idsubcategory
                  ? "#F0C9D5"
                  : "transparent",
              color: "#801F56",
              border: "2px solid #801F56",
              fontSize: "15px",
              padding: "5px 10px",
              margin: 5,
              display: "inline-block",
              justifyContent: "center",
            }}
            variant='outlined'
            onClick={() => setFilterItemSubcategory(sub.idsubcategory)}
          />
        ))}
      </Box>
      <IconButton
        onClick={() => scroll("right")}
        sx={{ visibility: subcategories.length > 5 ? "visible" : "hidden" }}
      >
        <ArrowForwardIos sx={{ color: "#801F56" }} />
      </IconButton>
    </Box>
  );
};

export default ScrollableSubcategories;
