import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  CircularProgress,
  Box,
  Grid,
  Typography,
  Paper,
  Alert,
} from "@mui/material";
import ProductsContext from "../../Context/Products/ProductsContext";
import MethodGet from "../../config/Service";
import CardProduct from "../../components/Products/CardProduct";

const SearchWithDebounce = ({ results, setResults }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(""); // Término con debounce
  const [loading, setLoading] = useState(false); // Estado de carga

  // Manejar cambios en el campo de búsqueda
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Debounce: Actualiza `debouncedTerm` después de 500ms
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler); // Limpia el timeout anterior
    };
  }, [searchTerm]);

  // Llamar a la API cuando `debouncedTerm` cambie
  useEffect(() => {
    const fetchData = async () => {
      if (!debouncedTerm) {
        setResults([]); // Limpia los resultados si no hay término de búsqueda
        return;
      }
      setLoading(true); // Activa el estado de carga
      try {
        let url = `/distribucion/p/search?q=${debouncedTerm}`;
        const response = await MethodGet(url);
        setResults(response.data); // Actualiza los resultados con la respuesta
      } catch (error) {
        console.error("Error al realizar la búsqueda:", error);
      } finally {
        setLoading(false); // Desactiva el estado de carga
      }
    };

    fetchData();
  }, [debouncedTerm]);

  return (
    <Box
      sx={{
        mx: "auto",
        mt: 4,
        px: 2,

        textAlign: "center",
      }}
    >
      {/* Campo de búsqueda */}
      <Typography variant='h5' component='h1' gutterBottom>
        Descubre el producto ideal para ti.
      </Typography>
      <TextField
        fullWidth
        // label='Buscar'
        variant='outlined'
        value={searchTerm}
        onChange={handleChange}
        placeholder='Escribe algo para buscar...'
        sx={{
          marginBottom: 3,
          backgroundColor: "#fff",
          borderRadius: 1,
          maxWidth: "90%",
          boxShadow: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#D87390", // Color inicial del borde
            },
            "&:hover fieldset": {
              borderColor: "#D87390", // Color del borde al pasar el cursor
            },
            "&.Mui-focused fieldset": {
              borderColor: "#D87390", // Color del borde cuando está enfocado
            },
          },
        }}
      />

      {/* Indicador de carga */}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress size={32} />
        </Box>
      )}

      {/* Resultados */}
      <Box sx={{ mt: 2 }}>
        {results.length > 0 ? (
          <Grid container spacing={3}>
            {results.map((result, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <CardProduct product={result} />
              </Grid>
            ))}
          </Grid>
        ) : (
          !loading &&
          results.length > 0 && (
            <Typography variant='body1' color='textSecondary' sx={{ mt: 4 }}>
              <Alert severity='warning'>
                No se encontraron resultados que coincidan con los criterios de
                búsqueda.
              </Alert>
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
};

export default SearchWithDebounce;
