import { Header1 } from "./Header1";
import { Footer } from "./Footer";
import AuthContext from "../../Context/auth/AuthContext";
import { useContext } from "react";
import FloatingWhatsAppButton from "./FloatingWhatsappIcon";

const UserLayout = ({ children }) => (
  <>
    <header className='header'>
      <Header1 />
    </header>
    <main className='content'>{children}</main>
    <footer className='footer'>
      <Footer />
    </footer>
    <FloatingWhatsAppButton />
  </>
);

const NoAuthLayout = ({ children }) => (
  <>
    <header className='header'>
      <Header1 />
    </header>
    <main className='content'>{children}</main>
    <footer className='footer'>
      <Footer />
    </footer>
    <FloatingWhatsAppButton />
  </>
);

const Layout = ({ children }) => {
  const { usuario, loading } = useContext(AuthContext);

  // Mientras se verifica la autenticación, no renderizar nada (evita el parpadeo)
  if (loading) {
    return null; // O un loader si prefieres
  }

  return usuario ? (
    <UserLayout>{children}</UserLayout>
  ) : (
    <NoAuthLayout>{children}</NoAuthLayout>
  );
};

export default Layout;
